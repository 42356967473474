const zh = {
    index: {
        title: '欢迎访问姜堰古罗塘旅游文化景区~！',
        dynamic: "最新动态",
        notice: "景区公告",
        survey: "景区概况",
        surveyT: '姜堰古罗塘旅游文化景区位于泰州市姜堰区文化和商业中心, 占地42亩，总建筑面积近3万平方米，由金姜堰旅游发展有限公司投资3亿元打造。',
        position: "地理位置",
        selfDriving: '自驾游路线',
        timetable: "汽车客运时刻表",
        train: "列车时刻表",
        airport: "扬泰机场航班",
        culture: "历史文化",
        cultureT: '泰州姜堰古罗塘旅游文化景区青砖黛瓦、院落幽深、石鼓威严、青石铺路，集中体现了姜堰古民居的特征，展示出以人为本、朴实无华、用材考究、美观大方的特点。',
        today: "今日古罗塘",
        todayT: "名人文化、棋文化、书画文化、古民居文化、茶文化、商业文化大放异彩。古罗塘旅游文化景区已成为姜堰旅游新地标、文化产业大观园。2014年11月被批准为国家AAAA级景区。",
        more: "查看更多",
        ren: "人",
        qi: "棋",
        cha: "茶",
        ju: "居",
        yi: "艺",
        shang: "商",
        wei: "微信订阅",
        wang: "网上预订",
        jiam: "加盟商家",
        xuni: "虚拟旅游",
        map: "景区地图",
        hui: "回到顶部",
        footer: `© Copyright 2012-2024 姜堰古罗塘旅游文化景区 All Rights Reserved <br>
            IE7.0以上 1024*768 　泰州市金姜堰旅游发展有限公司主办 　Powered<br>
            by Lerx　技术支持： 江苏科瑞达
            苏ICP备19014745号`,
        dyfw: "导游服务",
        wcsp: "文创商品",
        jdcy: "酒店餐饮",
        xwzx: "新闻资讯",
        xwzxT: "快速了解官方新闻动态和旅游活动相关资讯，以便于您的旅游出行",
        jqjd: "景区景点",
        jqjdT: "古韵罗塘，梦回千年——姜堰古罗塘文化之旅",
        zjglt: "走进古罗塘",
        zjgltT: '探寻姜堰古罗塘，一段流淌着历史的街区漫步',
        jqmt: "景区美图",
        jqmtT: "古罗塘韵致，姜堰景区的视觉盛宴",
        yqlj: "友情链接",
        yqljT: "携手古罗塘：共赏姜堰之美，链接友谊与合作",
    }
}
export default zh;