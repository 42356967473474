<template>
    <span>
        <counter class="value" :endVal="(endVal * 1)" :decimals="$utils.decimals(endVal)" separator="" />
    </span>
</template>

<script>
export default {
    props: {
        endVal: {
            type: [Number, String],
            default: 0
        }
    },
}
</script>

<style lang="scss" scoped></style>