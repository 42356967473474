let menuData = [
    { name: '领导驾驶舱', path: '/cockpit' },
    { name: '业态运营分析', path: '/operational' },
    { name: '智慧厕所', path: '/lavatory' },
    { name: '停车场', path: '/park' },
]
let emergencyCommand = [
    { name: '客流预警', path: '/emergencyCommandKl' },
    { name: '应急指挥', path: '/emergencyCommandYj' },
]
let touristCentre = [
    // { name: '景区全景', path: '/emergencyCommandKl' },
    // { name: '游览线路', path: '/emergencyCommandYj' },
]


let eventObj = {
    1: { color: "#34B012", name: '已上报,待指挥' },
    2: {
        color: "#F17D00", name: '已指挥,待处理'
    },
    3: {
        color: "#009DF1", name: '已处理,待评估'
    },
    4: {
        color: "#4275ee", name: '已评估'
    }
}


export function useInterval(callback, delay) {
    let intervalId;

    if (typeof callback === 'function' && delay > 0) {
        intervalId = setInterval(() => {
            callback();
        }, delay);
    }

    // 返回定时器的 ID，可以用于之后取消定时器
    return intervalId;
}

// 使用方法示例
export function stopInterval(intervalId) {
    if (intervalId && typeof intervalId === 'number') {
        clearInterval(intervalId);
    }
}


// conutTo 组件判断取小数后几位 
export function decimals(val) {
    return val.toString().includes('.') ? 2 : 0
}
export default {
    menuData,
    emergencyCommand,
    touristCentre,
    useInterval,
    stopInterval,
    decimals,
    eventObj
}