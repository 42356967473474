import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import utils from "./utils/index";
import ElementUI from "element-ui";
import { Tooltip } from 'element-ui';
import seamlessScroll from 'vue-seamless-scroll'
Vue.component('seamlessScroll', seamlessScroll)
import "element-ui/lib/theme-chalk/index.css";
import iView from 'iview'
import 'iview/dist/styles/iview.css'

Vue.use(iView)


import 'qweather-icons/font/qweather-icons.css'
//高德地图组件
import VueAMap from "vue-amap";
// 表格


import axios from "axios";
Vue.prototype.$axios = axios;
let { baseUrl } = require("../src/api/baseurl.js")
Vue.prototype.$baseUrl = baseUrl
Vue.use(VueAMap);
Vue.config.productionTip = false;
Vue.prototype.$utils = utils;
VueAMap.initAMapApiLoader({
  key: "01a36ff6989e51bdbd20d514101964bb",
  plugin: [
    "AMap.Autocomplete", //输入提示插件
    "AMap.PlaceSearch", //POI搜索插件
    "AMap.Scale", //右下角缩略图插件 比例尺
    "AMap.OverView", //地图鹰眼插件
    "AMap.ToolBar", //地图工具条
    "AMap.MapType", //类别切换控件，实现默认图层与卫星图、实施交通图层之间切换的控制
    "AMap.PolyEditor", //编辑 折线多，边形
    "AMap.CircleEditor", //圆形编辑器插件
    "AMap.Geolocation", //定位控件，用来获取和展示用户主机所在的经纬度位置
  ],
  uiVersion: "1.0",
});

// echarts组件
import * as echarts from "echarts";
Vue.prototype.$echarts = echarts;
import counter from 'vue-count-to'
Vue.component('counter', counter)
import countTo from "@/components/countTo/index.vue";
Vue.component('countTo', countTo)
// 饿了么UI组件
let tip = Tooltip
import rewriteElTooltip from "@/utils/rewriteElTooltip";
rewriteElTooltip(tip); // 重写el-tooltip的方法 注意一定要放在 Vue.use(ElementUI); 之前
Vue.use(ElementUI);
import { i18n } from './i18n/index.js';

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
