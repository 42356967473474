import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    activeMenu: sessionStorage.getItem("COMPANY_AUTH_INFO") || 0,
    secondaryMenu: sessionStorage.getItem("secondaryMenu") || [],
    language: sessionStorage.getItem("language") || 'title'
  },
  getters: {
  },
  mutations: {
    modifyData(state, payload) {
      state.activeMenu = payload
      sessionStorage.setItem("COMPANY_AUTH_INFO", payload)
    },
    modifyArray(state, payload) {
      state.secondaryMenu = payload
      sessionStorage.setItem("secondaryMenu", payload)
    },
    modifyLanguage(state, payload) {
      state.language = payload
      sessionStorage.setItem("language", payload)
      location.reload();
    },
  },
  actions: {
  },
  modules: {
  }
})
