<template>
  <div id="app" :class="$route.name">
    <ScaleBox :isSelf="true">
      <keep-alive>
        <router-view />
      </keep-alive>
    </ScaleBox>

  </div>
</template>

<script>
export default {
  components: {
    ScaleBox: () => import("@/components/ScaleBox"),
  },
  data() {
    return {
      list: ['officialWebsite', 'listPage', 'comprehensive', 'comprehensiveDettail']
    }
  },
}
</script>

<style lang="scss">
@import './base.scss';

// #guanwang {

//   .scale-box {
//     overflow-y: scroll;
//   }
// }

::-webkit-scrollbar {
  display: none;
  /* Chrome Safari */
}

.el-dialog__wrapper {
  pointer-events: none;
}

.comprehensive,
.comprehensiveDettail {
  .el-dialog__wrapper {
    pointer-events: auto;
  }
}

.easy-player-loading {

  .logo {
    display: none;
  }

  div.loading-text {
    color: #fff;
    background: transparent;
    -webkit-text-fill-color: #fff;
  }


}

.easy-video-player-inner {
  .vjs-stretch-control {
    display: none;
  }

  .vjs-bitrate-control {
    display: none;
  }

  .vjs-snapshot-control {
    display: none;
  }
}
</style>
