module.exports = {
    baseUrl: 'https://www.guluotang.cn/xcx/',//小程序后台接口
    shop: 'https://www.guluotang.cn',//商城后台接口
    imgUrl: "https://www.guluotang.cn/xcx", //接口图片地址
    production: 'https://www.guluotang.cn/xcx/',//打包地址


    // baseUrl: 'http://180.100.206.106:18082/jyxcx/',//小程序后台接口
    // shop: 'http://218.90.207.91:18080/',//商城后台接口
    // imgUrl: "http://180.100.206.106:18082/jyxcx", //接口图片地址
    // production: 'http://180.100.206.106:18082/jyxcx/',//打包地址
}